<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">Property Details</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">Property Details</span>
    </HeaderBasic>
    <div id="content">
      <div class="container">
        <div class="row">
          <div class="property-head">
            <div class="col-md-12">
              <h2 class="title-property">
                {{ fdata.prp_ShortDescription }}
              </h2>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="image"
              v-for="(image, imageIndex) in images"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{
                backgroundImage: 'url(' + image + ')',
                width: '300px',
                height: '250px'
              }"
            ></div>
            <VueGallery :images="images" :index="index" @close="index = null">
            </VueGallery>
          </div>
          <!-- Product Info Start -->
          <div class="product-info">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <div class="inner-box short-info">
                <h2 class="title-2">Property details</h2>
                <ul class="additional-details">
                  <li>
                    <strong>PROPERTY REF:</strong>
                    <span>{{ fdata.prp_PropertyCode }}</span>
                  </li>
                  <li>
                    <strong>OFFER TYPE:</strong>
                    <span>{{ fdata.prp_OfferType }}</span>
                  </li>
                  <li>
                    <strong>PRICE:</strong>
                    <span>{{ fdata.prp_PricePCM }}</span>
                  </li>
                  <li v-if="fdata.prp_Deposit != '£0'">
                    <strong>DEPOSIT:</strong>
                    <span>{{ fdata.prp_Deposit }}</span>
                  </li>
                  <li>
                    <strong>BEDROOMS:</strong>
                    <span>{{ fdata.prp_Bedrooms }}</span>
                  </li>
                  <li>
                    <strong>BATHROOMS:</strong>
                    <span>{{ fdata.prp_Bathrooms }}</span>
                  </li>
                  <li>
                    <strong>FURNISHING:</strong>
                    <span>{{ fdata.prp_FurnishType }}</span>
                  </li>
                  <li v-if="fdata.prp_ContactName">
                    <strong>CONTACT:</strong>
                    <span>{{ fdata.prp_ContactName }}</span>
                  </li>
                  <li>
                    <strong>PROPERTY ADDRESS:</strong>
                    <span>{{ fdata.prp_Address }}</span>
                  </li>
                  <li>
                    <strong>CITY:</strong>
                    <span>{{ fdata.prp_City }}</span>
                  </li>
                  <li>
                    <strong>SUBURB / LOCALITY:</strong>
                    <span>{{ fdata.prp_Suburb }}</span>
                  </li>
                  <li>
                    <strong>UPLOADED:</strong>
                    <span>{{ fdata.prp_DateAvailable }}</span>
                  </li>
                </ul>
              </div>

              <div v-if="fdata.prp_ShortDescription" class="inner-box details">
                <div class="property-dsc">
                  <h2 class="title-2">Property Description</h2>
                  <p>
                    {{ fdata.prp_ShortDescription }}
                  </p>
                </div>
              </div>

              <div v-if="fdata.prp_LongDescription" class="inner-box details">
                <div class="property-dsc">
                  <h2 class="title-2">Additional Information</h2>
                  <p v-html="fdata.prp_LongDescription"></p>
                </div>
              </div>

              <div v-if="fdata.prp_KeyFeatures" class="inner-box details">
                <div class="property-dsc">
                  <h2 class="title-2">Features</h2>
                  <p>
                    {{ fdata.prp_KeyFeatures }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- Product Info End -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueGallery from "vue-gallery";
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic"),
    VueGallery
  },
  props: {
    baseUrl: { type: String },
    property_ref: { type: String }
  },
  data() {
    return {
      fdata: {},
      images: [],
      index: null
    };
  },
  methods: {
    getPropertyByCode: async function() {
      if (!this.property_ref) {
        return;
      }
      const config = {
        params: { pcode: this.property_ref }
      };
      let actionUrl = `${this.baseUrl || "/PropertyFinder/"}GetPropertyByCode`;
      const {
        data: { rdata, rstatus }
      } = await axios.get(actionUrl, config);
      if (rstatus == 0 && rdata) {
        this.fdata = Object.assign({}, rdata);
        this.images = this.fdata.prp_ImageList.map(p => p.data);
      }
    }
  },
  async mounted() {
    await this.getPropertyByCode();
    this.$scrollTo("#content");
  }
};
</script>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 2px;
  cursor: pointer;
}
.image:hover {
  cursor: "zoom-in";
}
</style>
